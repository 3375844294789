.Doctor .fc .fc-toolbar .fc-button-group:first-child button {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    line-height: 9px;
}
.Doctor .fc-button .fc-icon {
    font-size: 10px;
}
.Doctor .fc .fc-toolbar .fc-left > h2 {
    font-size: 12px;
    margin-left: 5px;
    margin-top: 0;
}
.Doctor .fc-left {
    width: 100%;
}
.Doctor .fc .fc-toolbar {
    box-shadow: none;
    background: #fff;
    padding-left: 0;
    margin-bottom: 10px;
}
.Doctor .fc-toolbar > * > :not(:first-child) {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    justify-content: center;
    display: flex;
}
.Doctor .fc-button-group {
    margin-left: 4px;
}
.Doctor .fc-view-container {
    box-shadow: none;
}

.Doctor .fc-unthemed .fc-listWeek-view th,
.Doctor .fc-unthemed .fc-listWeek-view td
{
    padding: 2px;
}
.Doctor .fc .fc-row .fc-content-skeleton .fc-day-top:hover {
    background-image: none;
    cursor: default;
}
.Doctor .fc-list-empty {
    padding: 2px 10px 10px 10px;
    text-align: center;
    vertical-align: top;
    background: #fff !important;
}
.Doctor .fc-unthemed .fc-list-view {
    border-right: 0;
    border-bottom: 0;
}
.Doctor .fc-popover.fc-more-popover .fc-content {
    color: #fff;
}