.Layout{
    background-image: url('../../images/tlo-doc.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.LayoutPatient{
    background-image: url('../../images/bg-patient.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.LayoutDoctor{
    background: #fff url('../../images/tlo-no-doc.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.Layout .dropdown-item ,  .LayoutPatient .dropdown-item, .LayoutDoctor .dropdown-item {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: .3s;
    color: #3b4a4f;
}

.Layout .dropdown-item:hover ,  .LayoutPatient .dropdown-item:hover, .LayoutDoctor .dropdown-item:hover {
    color: #cccccc;
}

.Layout .dropdown-item:focus ,  .LayoutPatient.dropdown-item:focus , .LayoutDoctor .dropdown-item:focus, .Layout .dropdown-item:active ,  .LayoutPatient.dropdown-item:active , .LayoutDoctor .dropdown-item:active  {
    background-color: white;
}
body > div[role="presentation"],
body > div[role="tooltip"],
body > div[role="dialog"]
{
    z-index: 2300 !important;
}