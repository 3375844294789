@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';

.fc .fc-row .fc-content-skeleton td {
    border-color: inherit;
}
.fc-view-container {
    background: #fff;
    box-shadow: 0 0 35px rgba(21,47,54,0.27);
}
.Patient .fc-unthemed .fc-popover .fc-header {
    background: #0172bb;
}
.Patient .fc-scroller > .fc-day-grid {
    background: #f8f8f8;
}
.Patient .fc-unthemed td
{
    color: #0172bb;
    border-top: 0px solid #fff !important;
    border-left: 5px solid #fff !important;
    border-right: 5px solid #fff !important;
    border-bottom: 5px solid #fff !important;
}

.Patient .fc .fc-row .fc-content-skeleton td {
    border-color: transparent !important;
    border: 0 !important;
    padding: 1px 7px;
}
.Patient .fc .fc-row .fc-content-skeleton td.fc-day-top {
    border-top: 5px solid #fff !important;
}
.Patient .fc .fc-row .fc-content-skeleton td.fc-day-top > span {
    border-bottom: 3px solid #efeeee !important;
    width: 100%;
    text-align: right;
    margin: 0 -5px 0 0;
    padding-left: 5px;
}
.Patient .fc-unthemed td a.fc-day-grid-event,
.Patient .fc-unthemed td a.fc-time-grid-event .fc-time,
.Patient .fc-unthemed td a.fc-time-grid-event .fc-title
{
    color: rgba(0,0,0,0.7) !important;
}
.Doctor .fc-unthemed td a.fc-day-grid-event {
    color: #fff;
}
.fc .fc-toolbar .fc-left > h2 {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
@media (max-width: 991px) {
    .Patient .fc .fc-toolbar {
        padding-bottom: 5px;
    }
    .fc-toolbar > * > :not(:first-child) {
        margin-left: 0;
    }
    .fc .fc-toolbar .fc-right button:last-child {
        margin-left: 10px;
    }
}
.fc .fc-toolbar {
    min-height: 55px;
    padding-left: 10px;
    box-shadow: 0 0 35px rgba(21,47,54,0.27);
    background-color: #e9edf0;
    border-radius: 0.25rem !important;
}
.Patient .fc .fc-toolbar {
    color: #0172bb;
}
.fc .fc-toolbar .fc-button-group:first-child button {
    width: 51px;
    height: 51px;
    border-radius: 50% !important;
    padding: 0;
    margin-right: 10px;
    background: none;
    border: 0;
    font-weight: bold;
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: none;
}
.fc .fc-toolbar .fc-button-group:first-child button:hover  {
    background-color: rgba(0, 0, 0, 0.08);
}

.Patient .fc-button-primary
{
    background-color: #fff;
    border: 2px solid #fff;
    color: #0173bc;
    border-radius: 4px !important;
    font-weight: bold;
    transition: all 0.3s;
    height: 55px;
    padding-left: 30px;
    padding-right: 30px;
    text-transform: uppercase;
}
.Doctor .fc-button-primary {
    border: 2px solid #02a0be;
    color: #02a0be;
    background-color: transparent;
    border-radius: 4px !important;
    font-weight: bold;
    transition: all 0.3s;
    height: 35px;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: uppercase;
    box-shadow: none;
}
.Patient .fc-button-primary:not(:disabled).fc-button-active,
.Patient .fc-button-primary:hover,
.Patient .fc-button-primary:active
.Patient .fc-button-primary:focus
 {
    background-color: #139de8;
    border: 2px solid #139de8;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}
.Doctor .fc-button-primary:not(:disabled).fc-button-active,
.Doctor .fc-button-primary:hover,
.Doctor .fc-button-primary:active
.Doctor .fc-button-primary:focus
 {
    background-color: #02a0be;
    border: 2px solid #02a0be;
    color: #fff;
    border-radius: 5px;
    box-shadow: none;
}
.fc .fc-toolbar .fc-right button {
    width: 51px;
    height: 51px;
    border-radius: 50% !important;
    padding: 0;
    margin-right: 10px;
    background: none;
    border: 0;
    font-weight: bold;
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: none;
}
.fc .fc-toolbar .fc-right button:hover  {
    background-color: rgba(0, 0, 0, 0.08);
}
.fc .fc-toolbar .fc-right .fc-icon {
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 900;
}
button.fc-addEventButton-button.fc-button.fc-button-primary {
    width: auto;
    position: relative;
    padding-left: 65px;
    padding-right: 15px;
    margin-right: 0px;
    text-transform: uppercase;
    border-radius: 4px !important;
}
button.fc-addEventButton-button.fc-button.fc-button-primary:before {
    content: '';
    width: 41px;
    content: " ";
    background-image: url('../../images/button-red.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 15px;
    top: 5px;
    height: 41px;
}
.fc-time-grid-event .fc-title,
.fc-time-grid-event .fc-time {
    color: #fff;
}
.Patient .fc-event-container .fc-content .fc-time,
.Patient .fc-event-container .fc-content .fc-title
{
    color: rgba(0, 0, 0, 0.7);
}
.fc .fc-row .fc-content-skeleton .fc-day-top:hover {
    background-image: url('../../images/button-red.png');
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 8px 3px;
}
button.fc-filterMedicine-button > span,
button.fc-filterMeasurements-button > span,
button.fc-filterConsultations-button > span {
    color: #cacaca;
    width: 26px;
    height: 26px;
}
button.fc-filterMedicine-button > span.active,
button.fc-filterMeasurements-button > span.active,
button.fc-filterConsultations-button > span.active {
    color: #0000008a;
}

button.fc-exportButton-button.fc-button.fc-button-primary span:after,
button.fc-filterMedicine-button.fc-button.fc-button-primary span:after,
button.fc-filterMeasurements-button.fc-button.fc-button-primary span:after,
button.fc-filterConsultations-button.fc-button.fc-button-primary span:after
{
    font: 400 11px system-ui;
}
button.fc-exportButton-button.fc-button.fc-button-primary:hover span:after,
button.fc-filterMedicine-button.fc-button.fc-button-primary:hover span:after,
button.fc-filterMeasurements-button.fc-button.fc-button-primary:hover span:after,
button.fc-filterConsultations-button.fc-button.fc-button-primary:hover span:after
{
    position: absolute;
    top: -20px;
    margin-left: -70px;
    color: #fff;
    text-transform: initial;
    width: 120px;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
    height: 20px;
    font-weight: 600;
    font-size: 10px;
    background: rgba(97, 97, 97, 0.9);
    content: attr(data-text);
}
.fc-prev-button span,
.fc-next-button span {
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 900;
}

.fc-prev-button span:after,
.fc-next-button span:after
{
    font: 400 11px system-ui;
}

.Patient .fc-prev-button:hover span:after,
.Patient .fc-next-button:hover span:after
{
    position: absolute;
    top: -22px;
    margin-left: -50px;
    color: #fff;
    text-transform: initial;
    width: 90px;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
    height: 20px;
    font-weight: 600;
    font-size: 10px;
    background: rgba(97, 97, 97, 0.9);
    content: attr(data-text);
}
.fc-more-popover .fc-event-container {
    max-height: 200px;
    overflow-y: auto;
}
.Patient .fc-day-header {
    background: #139de8;
    color: #fff;
    border: 1px solid #139de8 !important;
    text-transform: capitalize;
    font-size: 17px;
}
.Patient .fc-widget-header .fc-widget-header {
    border: 1px solid #139de8 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.Patient td.fc-head-container {
    padding: 12px 50px !important;
    background: #139de8 !important;
    border-color: #139de8 !important;
}
.Patient .fc-head {
    border: 0;
}
.Patient .fc-scroller {
    padding: 40px 50px;
}
.Patient .fc-timeGridWeek-view .fc-scroller {
    padding: 0px 50px 30px 50px ;
}
.Patient .fc-scroller > .fc-time-grid {
    background: #f8f8f8;
}
.Patient .fc-scroller > .fc-time-grid td
{
    color: #0172bb;
    border-top: 0px solid #fff !important;
    border-left: 5px solid #fff !important;
    border-right: 5px solid #fff !important;
    border-bottom: 0px solid #fff !important;
}
.Patient .fc-more {
    color: red !important;
}
.Patient .fc-timeGridWeek-view .fc-day-grid .fc-bg,
.Patient .fc-timeGridWeek-view .fc-day-grid .fc-content-skeleton
{
    padding: 0px 50px;
}
.Patient .fc-timeGridWeek-view .fc-day-grid .fc-bg td {
    background: #f8f8f8;
}
.Patient .fc-timeGridWeek-view .fc-day-grid .fc-bg td.fc-today {
    background: #fcf8e3;
}
.Patient .fc-timeGridWeek-view .fc-time-grid .fc-slats .fc-axis,
.Patient .fc-timeGridWeek-view .fc-week .fc-bg .fc-axis
{
    border-right: 5px solid #efeeee !important;
}