.modal-open {
    overflow: hidden;
    padding-right: 15px;
}
.modal-open .MuiToolbar-regular {
    padding-right: 39px;
}
.Modal {
    position: fixed;
    padding: 22px;
    z-index: 2200;
    right: 0;
    overflow: auto;
    height: 100%;
    width: 100%;
    max-width: 100%;
    top: 0;
}

.Modal .modal-content {
    border: 0;
}

.ModalBg {
    min-height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right:0;
    padding: 12px;
    background-color: rgba(2, 160, 190, 0.8);
    overflow: hidden;
    z-index: 2200;
}
.Modal .modal-dialog {
    box-shadow: 0 0 22px rgba(21,47,54, 0.5);
}

.Modal .modal-title {
    line-height: 36px;
    color: white;
    font-family: "Montserrat";
    font-weight: 600;
    margin-left: 34px;
}

.Patient .ModalBg {
    background-color: #0173bc;
    opacity: 0.8;
    z-index: 2200;
}

.Modal .modal-header {
    background-color: #2fc8e8;
}

.Patient .Modal .modal-header {
    background-color: #0173bc;
}

.Modal button.close {
    position: relative;
    box-shadow: 0 0 10px rgba(0,0,0,0.31);
    top: -20px;
    right: -20px;
    background: #fe4332;
    color: white;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 25px;
    font-weight: 700;
    opacity: 1;
}

.Modal button.close:hover {
    background-color: #d3382a;
    opacity: 1!important;
}

.Modal button.close i:hover {
    color: '#cccccc;';
    opacity: 1;
}

.Modal .buttons {
    margin-top: 112px;
    margin-bottom: 60px;
}