body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   background-color: #f7f8fa;
}
body > #root {
  position: relative;
  z-index: 0;
}
.font-size-14 {
  font-size: 87.5%;
}

.font-size-16 {
  font-size: 100%;
}

.font-size-20 {
  font-size: 125%;
}

.font-size-24 {
  font-size: 150%;
}


body.text-contrast span,
body.text-contrast label,
body.text-contrast p,
body.text-contrast i,
body.text-contrast i::before,
body.text-contrast i::after,
body.text-contrast h1,
body.text-contrast h2,
body.text-contrast h3,
body.text-contrast h4,
body.text-contrast h5,
body.text-contrast div,
body.text-contrast span,
body.text-contrast a,
body.text-contrast td,
body.text-contrast th,
body.text-contrast button,
body.text-contrast input,
body.text-contrast header,
body.text-contrast li,
body.text-contrast li.slide.selected,
body.text-contrast ul,
body.text-contrast input::before,
body.text-contrast input::after,
body.text-contrast input::placeholder,
body.text-contrast input[value]
{
  color: yellow!important;
  background-color: black!important;
}
body.text-contrast input, body.text-contrast div.MuiInput-input {
  border: 1px solid yellow!important;
}

body.text-contrast i, 
body.text-contrast .MuiButton-label,
body.text-contrast .MuiListItemText-root,
body.text-contrast .MuiListItemText-root span {
  z-index: 100!important;
}

body.text-contrast .Layout, body.text-contrast .LayoutPatient, body.text-contrast .LayoutDoctor {
  background-image: unset!important;
}

body.text-contrast button.MuiButton-outlined, 
body.text-contrast button.MuiButton-text, 
body.text-contrast button.fc-button
{
    border: 2px solid yellow!important;
}

body.text-contrast button.MuiButton-contained, 
body.text-contrast button.fc-button-active
{
    border: 2px solid red!important;
}

body.text-contrast div.MuiPopover-root, 
body.text-contrast div.MuiPopover-root > div, 
body.text-contrast div.MuiPopover-root > div span,   
body.text-contrast div#react-select-single span,
body.text-contrast div[class$="-menu"] div div span
{
  background-color: transparent!important;
  color: yellow!important
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.insetError {
    box-shadow: 0 2px 0 1px red;
                /* inset 0 -3px 0 0 red; */
}

#modal {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.Patient .fileContainer .UploaderButton {
    border-radius: 4px;
    border: 0;
    transition: '0.3s';
    max-width: inherit;
    /* width: 201px; */
    height: 55px;
    font-family: 'Arial';
    font-weight: 700;
    letter-spacing: 0;
    background-color: #0173bc;
    color: white;
    text-transform: uppercase;
}

.Patient .fileContainer .UploaderButton:hover {
    background-color: #015285;
    color: #cccccc;
}

.Doctor .fileContainer .UploaderButton {
    border-radius: 4px;
    border: 0;
    transition: '0.3s';
    max-width: inherit;
    height: 55px;
    font-family: 'Arial';
    font-weight: 700;
    letter-spacing: 0;
    background-color: #02a0be;
    color: white;
    text-transform: uppercase;
}

.Doctor .fileContainer .UploaderButton:hover {
    background-color: #02687c;
    color: #cccccc;
}