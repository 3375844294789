 @keyframes pulse {
  20% {
    transform: translateX(19px) translateY(0px); 
  } 
  40% {
    transform: translateX(31px) translateY(-18px); 
  } 
  55% {
    transform: translateX(42px) translateY(17px); 
  } 
  75% {
    transform: translateX(52px) translateY(0px); 
  } 
  100% {
    transform: translateX(75px) translateY(0px); 
  } 
}

.RemedizerPulse {
    animation: pulse infinite 1s linear;
}