.footer {
    height: 230px;
    background-color: #02a0be;
    margin-top: 145px;
}

.footer-padding {
    padding-top: 92px;
    padding-bottom: 92px;
    line-height: 30px;
}

.Doctor .footer {
    background-color: #02a0be;
}

.Patient .footer {
    background-color: #0173bc;
}